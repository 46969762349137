// Mixins 

@mixin respond-to($size) {
    @media only screen and (min-width: $size) {
        @content;
    }
}


@import url('https://fonts.googleapis.com/css?family=Raleway:300,400|Roboto+Slab:300,400&display=swap');


:focus {
    outline: none;
}

*{
    box-sizing: border-box;
}

html{
    font-size: 62.5%;
}

body{
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
}

img{
    max-width: 100%;
    height: auto;
    border: 5px solid #debb8f;
    @include respond-to(768px){
        border: 15px solid #debb8f; 
    }
}

figcaption{
    font-style: italic;
    text-align: center;
    opacity: 0.75;
    font-size: 0.85rem;
    padding: 1rem;
}

ol, ul{
    padding: 0;
    margin: 2.5em 0;
   
    @include respond-to(768px){
        padding-left: 2.5em; 
    }
}

ul li{
    list-style: disc;
}

li{
    list-style: none;
    margin: 1em 0;
}

p{
    // line-height: 1.5rem;
    margin: 1em 0;
}

button{
    cursor: pointer;
    &:disabled{
        cursor: default;
    }
}

header{
    background: rgb(47, 49, 58);
    a{
        color: #FFF;
        display: inline-block;
        padding: 12px;
        letter-spacing: 0.05rem;
        font-size: 18px;
        font-weight: 100;
        text-decoration: none;
        text-transform: lowercase;
        &:hover{
            color: #debb8f;
        }
        &:first-of-type{
            padding-left: 0;
        }
    }
}

.input_control{
    margin: 1em 0;
}

.error{
    color: rgb(222, 187, 143);
    font-size: 1.4rem;
}

.footer{
    position: fixed;
    width: 100%;
    padding: 1em;
    color: #FFF;
    bottom: 0;
    background: #2e313a;
}

.footer a{
    color: #fff;
}

.container{
    max-width: 700px;
    margin: 0 auto;
    padding: 1rem;
}


.posts{
    padding: 0;
}

.endofposts, .loadmore{
    display: block;
    width: 100%;
    background: #2e303a;
    color: #debb8f;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem;
    border: 3px solid #debb8f;
}

.content{
    background-color: rgb(47, 49, 58);
    color: #FFF;
    padding: 1em;

    figure{
        margin: 0;
    }

    img{
        max-width: 45%;
        border-width: 7.5px;
        display: block;
        margin: 0 auto;

        &.alignleft{
            float: left;
            margin-right: 2em;
        }
        

    }

}

.day{
    
    background-color: rgb(47, 49, 58);
    color: #FFF;
    display: grid;
    grid-template-columns: 100%;
    margin: 40px 0;
    padding: 1em;
    @include respond-to(900px){
        grid-template-columns: 70% 30%;
    }

    figure{
        width: 90%;
        display: block;
        margin: 2.5rem auto;
        @include respond-to(768px){
            width: 75%; 
        }
    }


}

.day a{
    color: rgb(222, 187, 143);
}

.day--blog{
    grid-template-columns: 100%; 
    position: relative;
}

.date{
    position: relative;
    margin-top: 0;
    color: #FFF;
    text-decoration: underline;
}

.date .fa-clock{
    padding-right: 6px;
}

// .summary{
//     padding: 1em;
// }

.summary__text{
    line-height: 1.5rem;
}

.spend{
    background-color: rgb(47, 49, 58);
    color: white;
    border-left: 3px solid rgb(222, 187, 143);
}

.spend__total{
    color: rgb(222, 187, 143);
    display: block;
    padding: 1em;
}

.spend__breakdown{
    padding: 1em;
    list-style-type: none;
}

.spend__breakdown li:not(:last-of-type){
    padding-bottom: 10px;
}

.spend__breakdown li p{
    margin: 0;
    display: inline;
}

.spend__breakdown .fas{
    padding-right: 5px;
}



.log{
    margin: 40px 0;
    background-color: rgb(47, 49, 58);
    color: white;
    border-left: 3px solid rgb(222, 187, 143);
}


.comments{

    input, textarea{
        width: 100%;
        padding: 1em;
        border-radius: 0;
        border: 2px solid #2f313a;
    }

    input[type="submit"]{
        cursor: pointer;
    }

}

.comment{
    
    border: 2px solid #2f313a;
    padding: 1em;
    margin: 1.5em 0;

    &__meta{
        display: flex;
        p{
            margin: 0;
        }
    }

    &__author_image{
        border-width: 4px;
        margin-right: 1em;
    }


    &__content{
        border-top: 1px solid #e7e7e7;
        margin-top: 1em;
    }

}

.love{
    cursor: pointer;
}

.read_more{
    text-align: right;
    padding: 1em;
    &:hover{
        color: #FFF;
    }
}

.heartCount{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.heart{
    zoom: 50%;
}

.count{
    margin-top: -20px;
    font-size: 11px;
}